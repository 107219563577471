import Link from 'next/link';

import { Button } from '@/shared/components/ui/button';
import { Paper } from '@/shared/components/ui/paper';

export const NotFoundPage = () => {
  return (
    <Paper className="flex min-h-full py-4" variant="dot">
      <div className="container flex min-h-full flex-col items-center justify-center space-y-4">
        <h1 className="text-4xl">404 Error</h1>

        <span className="text-md text-center text-muted-foreground ">
          The page you are looking for was moved, removed, renamed or might never existed
        </span>

        <Button asChild>
          <Link href="/">Go home</Link>
        </Button>
      </div>
    </Paper>
  );
};
